import React, { useRef, useState } from 'react';
import {
  AquistaButton,
  AquistaButtonsContainer,
  CompanyInfoElements,
  CompanyInformationContainer,
  LeftCol,
  LogoContainer,
  LogoPriceContainer,
  Price,
  PriceContainer,
  ProfessionCardStyles,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './ProfessionCard.styles';
import TokioMarineLogo from '../../../../assets/images/Tokio_Marine.svg.png';
import preventiveIcon from '../../../../assets/redesign_icons/preventivo-icon.svg';
import preContractIcon from '../../../../assets/redesign_icons/pre-contract-icon.svg';
import { downloadResourceDocs } from '../../../../services/axios-client/axeCommons';
import { SavedButton } from './common/Buttons.styles';
import SparklesIcon from '../../../../assets/images/icons/SparklesIcon';
import { Chat } from '../../chats/MiniChat/MiniChat';

function ProfessionRiskCard({
  quote,
  requestInfo,
  selectQuotation,
}) {
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();

  console.log(quote);

  const extraText = `Massimale: ${quote.massimale != 0 ? `Massimale: ${quote.massimale}` : ' '} - Copertura: ${quote.copertura}`;

  return (
    <ProfessionCardStyles>
      <div className="row-container">
        <LeftCol>
          <button
            onClick={() => setOpenChat(true)}
            className="open-chat-button border-0  text-white bg-success rounded p-1"
            style={{ position: 'absolute', right: '30px', top: '30px' }}
          >
            <SparklesIcon width={40} height={40} />
          </button>
          <Chat
            ref={chatRef}
            openChat={openChat}
            setOpenChat={setOpenChat}
            company_id={quote.company_id}
            product_id={quote.product_id}
            quote_type={quote.quote_type}
            company_name={quote.company.name}
            extraText={extraText}
          />
          <LogoPriceContainer>
            <LogoContainer>
              <img src={TokioMarineLogo} alt="Tokio Marine Logo" />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>Prezzo annuo:</h5>
                <h2>
                  &euro;
                  {quote.amount.toFixed(2).toLocaleString()}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
            {quote.massimale != 0 && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {quote.massimale}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
          <QuoteInformationContainer className="ms-3 ms-sm-0">
            <QuoteInformationItem>
              <h6>Copertura:</h6>
              <p>{quote.copertura}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            <div className="mobile-width">
              {requestInfo.tutela_legale_quote_id !== quote.id ? (
                <AquistaButton
                  onClick={() => selectQuotation(quote.id)}
                  className="result-profession-sixth-step"
                >
                  Seleziona
                </AquistaButton>
              ) : (
                requestInfo.tutela_legale_quote_id === quote.id && (
                  <SavedButton>Aggiunto</SavedButton>
                )
              )}
            </div>
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </ProfessionCardStyles>
  );
}

export default ProfessionRiskCard;
